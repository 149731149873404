html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #990000;
}

section {
  margin: 20px 0 20px 0;
}

p,
small {
  color: #919191;
  text-align: justify;
  text-justify: inter-word;
}

h3 {
  font-size: 25px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: 0px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 2px solid #990000;
  width: 40px;
  margin: 20px 0 30px;
}

footer {
  background-color: #f7f7f7 !important;
  color: #919191;
}

.nav-link {
  font-size: 0.8rem !important;
  font-weight: 600;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.9rem;
    padding-left: 0.9rem;
  }
}

/* Home */

.como-atuamos {
  background-image: url("./assets/como-atuamos.jpg");
  width: 100%;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
}

section#areaatuacao {
  background-color: #f4f4f4;
}

.line-height p {
  line-height: 37px;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 279px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 279px;
  width: 100%;
}

.icon{
  font-size: 25px;
  color: #999;
  margin-right: 10px;
}

.icon:hover{
  color: #990000;
}
